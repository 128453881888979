.main{
    background-color: #121214;
    color: #eee;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
}

.container{
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form{
    width: 400px;

    padding: 5px;

    border: 4px solid #565656;
    border-radius: 15px;
}

.form__header{
    font-size: 24px;
    font-weight: 600;
    line-height: 100%;

    display: flex;
    justify-content: center;

    width: 100%;
    margin: 10px auto;
}

.settings__header{
    font-size: 18px;
    font-weight: 400;
    line-height: 100%;

    display: flex;
    justify-content: center;

    width: 100%;
    margin: 10px auto;
}

.settings__grid{
    display: grid;
    grid-template-columns: 40px auto;
    

    justify-content: center;
    align-items: center;

    margin: 5px 0;
}

.settings__grid__row_checkbox{
    display: grid;
    grid-template-rows: 30px 30px;

    justify-content: center;
    justify-items: center;
    align-items: center;
}

.settings__grid__row_txt{
    display: grid;
    grid-template-rows: 30px 30px;

    justify-content: center;
    align-items: center; 
}

.checkbox{
    height: 20px;
    width: 20px;

    background-color: #d3d3d3;
}

.length_input{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 5px 0;
}

.length_input__text{
    font-size: 18px;
    font-weight: 400;
    line-height: 100%;

    display: flex;
    justify-content: center;

    width: 100%;
    margin: 15px auto 10px auto;
}

.length_input__description{
    font-size: 12px;
    font-weight: 400;
    line-height: 100%;

    display: flex;
    justify-content: center;

    width: 100%;
    margin: 4px auto;
}

.input{
    height: 20px;
    width: 80%;

    font-size: 16px;

    background-color: #d3d3d3;

    border: none;
}

.password{
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.password__header{
    font-size: 18px;
    font-weight: 400;
    line-height: 100%;

    display: flex;
    justify-content: center;

    width: 100%;
    margin: 5px auto 10px auto;
}

.password_input{
    height: 20px;
    width: 80%;

    display: flex;

    margin-bottom: 15px;
}

.password_text{
    height: 20px;
    width: 100%;

    font-size: 16px;

    background-color: #d3d3d3;

    border: none;
}

.copy{
    height: 22px;
    width: 40px;

    border: none;
    background-color: #ccc;
}

.buttons{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.copy_btn{
    display: none;

    height: 25px;
    width: 80%;

    border: none;

    margin-bottom: 15px;
}

.generate_button{
    height: 25px;
    width: 80%;

    border: none;

    margin-bottom: 15px;
}

@media screen and (max-width: 600px){
    .input{
        height: 30px;
        width: 80%;
    
        font-size: 24px;
    
        background-color: #d3d3d3;
    
        border: none;
    }

    .password_text{
        height: 30px;
        width: 100%;
    
        font-size: 24px;
    
        background-color: #d3d3d3;
    
        border: none;
    }

    .copy{
        display: none;
        height: 32px;
        width: 40px;
    
        border: none;
        background-color: #ccc;
    }

    .copy_btn{
        display: block;
    
        height: 35px;
        width: 80%;
    
        border: none;
    
        margin-top: 10px;        
        margin-bottom: 15px;
    }
    
    .generate_button{
        height: 30px;
        width: 80%;
    
        border: none;
    
        margin-bottom: 15px;
    }
}